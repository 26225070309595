@import '../../default/variables.scss';

.rent{
    display: block;
    color: $primary-color;
    background-color: #f1f1f1;
    border-radius: 9px;
    padding: 1rem;
    height: auto;

    &__price{
        margin-top: 1rem;
        text-align: left;
        font-size: 1.2rem;
        font-weight: bold;
    }
    &__title{
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
    }

    &__form{
        padding:1rem 2rem;

    }

    &__form__date{
        display: flex;
        justify-content: space-between;
        grid-gap: 5rem;
    }

    &__form__date div{
        width: 100%;
    }

    &__form__date div label{
        display: flex;
        flex-direction: column;
        font-weight: bold;
        padding-bottom: .7rem;
    }

    &__form__date div input{
        width: 100%;
        border: none;
        background-color: #D9D9D9;
        padding: .5rem;
        box-shadow: 1px 1px 2px 1px #a0a0a0;
        border-radius: 9px;
    }

    &__form__calc{
        display: flex;
        justify-content: space-between;
        padding-top: 2rem;
        padding-bottom: 1rem;
        align-items: center;
        label{
            font-weight: bold;
        }
        input{
            width: 70%;
            justify-content: space-between;
            border: none;
            background-color: #D9D9D9;
            padding: .5rem;
            box-shadow: 1px 1px 2px 1px #a0a0a0;
            border-radius: 9px;
        }
    }
}

@media (max-width: 780px) {
    .rent{
        width: 100%;
        margin: 0 auto;
        &__form{
            padding: 1rem .875rem;
        }
        &__form__date{
            display: block;
            width: 100%;
            justify-content: space-between;
        }
    
        &__form__date div{
            width: 100%;
            padding:  1rem 0;
            input{
                width: 100%;
            }
        }
    }
}