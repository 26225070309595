
@media (max-width: 768px){
    .lpnest{
        &__title{
            font-size: 2rem;
            line-height: 40px;
        }
        iframe{
            width: 100%;
            height: auto;
        }

        &__text{
            font-size: 1.3rem;
        }
    }
}
