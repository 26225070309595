.solutions{
    width: 100%;
    margin: 4rem auto;
    padding: 0 5rem ;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    grid-gap: 2rem;

}

@media (max-width: 768px){
    .solutions{
        margin-top: 1rem;
        display: flex;
        width: 100%;
        padding: 1rem;
        flex-direction: column;
    }
}