.carousel{
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0 0;
    margin-top: 4rem;
    a{
        text-decoration: none;

    }
    &__item{
        grid-gap: 1rem;
        text-align: center;
        &__imgDesk{
            width: 370px;
            height: 270px;
        }

        &__imgMob{
            width: 100%;
            height: auto;
            object-fit: contain;
        }

        h4{
            text-align: center;
            font-size: 1.3rem;
            color: #F56D0A;
        }
    }

    &__rec{
        &__rec-dot_active{
            background-color: #F56D0A;
        }
        
    }

}

