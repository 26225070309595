.whatsapp_float{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    bottom: 90px;
    right: 25px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    text-decoration: none;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    &__whatsapp-icon {
        margin-top: 16px;
    }
}


@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 90px;
        right: 30px;
        font-size: 22px;
    }
}
