.demonstration{
    width: 100%;
    height: auto;
    margin: 4rem auto;
    text-align: center;
    padding: 0 5rem;
    padding-bottom: 2rem;
    &__title{
        text-align: center;
        font-size: 2rem;
    }
    &__products{
        display: grid;
        width: 100%;
        height: auto;
        padding: 2rem 0;
        margin: 0 auto;
        grid-template-columns: repeat(8, 1fr);
        align-items: flex-end;
        &__text{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: end;
            height: 300px;
            width: 100%;
            position: relative;
            top:4px;
            padding: 0 1rem;
            ul{
                padding: 0;
                list-style: none;
                text-align: left;
                display: flex;
                flex-direction: column;
                height: auto;
                justify-content: end;
                align-items: center;
                width: 100%;
                li{
                    padding: 1.2rem .5rem;
                    width: 100%;
                    height: 53px;
                    line-height: 20px;
                    font-weight: bold;
                    
                }
            }       
    
        }

 
        
        &__container{
            a{
                text-decoration: none;
                text-align: center;
                color: #EA662C;
                
            }
            a:hover{
                color: #125082;
            }
            &__product{
                
                img{
                    width: 150px;
                    height: auto;
                    object-fit: contain;
                }
                
            }
            &__list{
                list-style: none;
                width: 100%;
                box-sizing: border-box;
                height: 275px;
                padding: 0;
                margin: 0 auto;
                li{
                    background-color: #d9d9d9;
                    width: 100%;
                    padding: 1rem .5rem;
                    margin: 0 auto;
                    font-weight: 500;
                    text-align: center;
                }
            }
        }
    }

    &__btnAgendar{
        background-color: #125082;
        border: none;
        box-shadow: 1px 2px 3px 1px #125082;
        padding: 1rem;
        margin: 1rem auto;
        border-radius: 9px;
        text-align: center;
        a{
            text-decoration: none;
            color: #fff;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 1rem;
        }
    }
    &__c1{
        background-color: #ccc;
    }
    
    &__c2{
        background-color: rgb(119, 89, 89);
    }
}

@media (width: 1366px){
    .demonstration{
        &__products{
            &__text{
                ul{
                    li{
                        padding: .5rem .5rem;
                        width: 100%;
                    }
                }       
        
            }
   }}         
}

@media (max-width: 768px){
    .demonstration{
        padding: 0;
        &__title{
            text-align: center;
            font-size: 1.2rem;
            padding: 0;
            margin-top: -1rem;
        }
        &__products{
            display: flex;
            flex-direction: column;
            margin-top: -8rem;
            &__container{
                margin-top: 8rem;
                width: 100%;
                position: relative;
            
                &__list{
                    li{
                        height: 62px;
                        display: flex;
                        align-items: center;
                        text-align: left;
                        padding:  1rem;
                        
                    }
                }
            }
            &__text{
                ul{
                    li{
                        padding: .5rem .5rem;
                        width: 100%;
                    }
                }       
        
            }
        }
        &__btnAgendar{
            margin-top: 3rem;
        }
    }         
}
   

