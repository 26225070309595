.formWebLp{
    margin: 1rem auto;
    width: 50%;
    box-shadow: 1px 0px 2px 2px #ccc;
    border-radius: 8px;
    padding: 1rem;
    &__divBox{
        padding: .5rem;

        label{
            display: block;
            text-align: left;
        }
        input{
            width: 100%;
            padding: .5rem;
            border: 1px solid #125082;
            border-radius: 8px;
        }

        textarea{
            width: 100%;
            height: 100px;
            border: 1px solid #125082;
            border-radius: 8px;
        }
    }
}

@media (max-width: 768px){
    .formWebLp{
       width: 100%;
    }
}