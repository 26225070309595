.home{
    width: 100%;
    height: auto;
    margin: .1rem auto;
    z-index: 24;

    &__bannerMob{
        width: 100%;
        height: auto;
        object-fit: contain;
        margin-top: -.2rem;
    }
    &__banner{
        height: auto;
        img{
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    @media (min-width: 1400px){
        &__banner{
            height: 450px;
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }


    &__concepts{
        padding: 1rem 4rem;
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: flex;
        grid-gap: 4rem;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: center;
        &__box{
            width: 33.33%;
            background-color: #44963B;
            border-radius: 8px;
            text-align: center;
            padding: 0 2rem 5rem 2rem;
            height: 440px;
            h2{
                color: #fff;
                margin-top: -1rem;
                font-size: 2rem;
                letter-spacing: 1px
            };
            p{
                font-size: 1rem;
                color: #fff;
                margin-top: -1rem;
                padding-bottom: 1rem;
            }

            &__icon{
                width: 100px;
                height: 100px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                background-color: #fff;
                text-align: center;
                position: relative;
                bottom: 2rem;
                box-shadow: 1px 0px 5px 0px rgb(180, 180, 180);
            }
        }
    }


    &__enterprise{
        padding: 4rem 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__img{
            width: 50%;

            img{
                object-fit: cover;
            }
        }

        &__whoiam{
            width: 50%;
            label{
                display: block;
                font-size: 1.5rem;
                font-weight: bold;
                color: #125082;
            }
            h2{
                margin-top: -4px;
                font-size: 3rem;
                color: #125082;
            }
            p{
                margin-top: -.5rem;
                padding: 1rem 0;
                letter-spacing: 1px;
                font-weight: 600;
                font-size: 1rem;
            }
        }
    }

    &__rent{
        width: 100%;
        margin: 3rem auto;
        padding: 4rem;
        display: flex;
        height: 350px;
        grid-gap: 4rem;
        justify-content: space-between;
        align-items: center;
        background-color: #44963B;

        &__text{
            h2{
                font-size:3rem;
                font-weight: 700;
                text-transform: uppercase;
                margin-top: -1rem;
                color: #fff;

            }

            p{
                width: 90%;
                letter-spacing: 1px;
                font-size: 1rem;
                margin-top: -1rem;
                color: #fff;
            }
            a{
                cursor: pointer;
                button{
                    width: 193px;
                    color: #fff;
                    height: 52px;
                    border: none;
                    font-size: 1.3rem;
                    letter-spacing: 1px;
                    font-weight: bold;
                    box-shadow: 1px 0px 5px 0px #0f436d;
                    border-radius: 25px;
                    background-color: #125082;
                    cursor: pointer;
                }
            }

            
        }

        img{
            position: relative;
            bottom: 0rem;
            width: 750px;
            height: auto;
            object-fit: cover;
        }
    }

    &__enterprise2{
        padding: 4rem 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__img{
            width: 50%;
            img{
                object-fit: cover;
            }
        }

        &__text{
            width: 50%;
            h3{
                text-align: center;
                font-size: 2rem;
                color: #125082;
                line-height: 45px;
            }

            h4{
                text-align: center;
                font-size: 1.2rem;
                color: #125082;
            }
            p{
              text-align: center;
              font-weight: bold;  
              font-size: 1rem;
            }
        }   
    }

    &__plat{
        padding: 4rem;
        width: 100%;
        margin: 0 auto;

        &__icons{
            padding: 0 4rem;
            &__box{
                display: flex;
                grid-gap: 4rem;
                align-items: center;
                justify-content: space-between;
                div{
                    padding: 1rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 2rem;
                    width: 247px;
                    border-radius: 27px;
                    height: 70px;
                    box-shadow: 1px 0px 5px 0px #ccc;
                    label{
                        font-weight: 700;
                        width: 60%;
                        text-align: center;
                        color: rgb(114, 114, 114);
                    }
                }

            }
        }

        h2{
            text-align: center;
            font-size: 3rem;
            color: #125082;
            font-weight: bold;
        }
    }

    &__tec{
        width: 100%;
        margin: 0 auto;
        padding: 4rem;

        h2{
            text-align: center;
            font-size: 3rem;
            color: #125082;
            width: 60%;
            margin: 0 auto;
            font-weight: bold;
        }
    }
}

@media (max-width: 768px){

    .home{
        width: 100%;
        &__bannerMob{
            height: auto;
            img{
                width: 100%;
                height: 450px;
                object-fit: cover;
            }
        }
        &__concepts{
            width: 100%;
            height: auto;
            margin: 2rem auto;
            display: flex;
            flex-direction: column;
            grid-gap: 4rem;
            justify-content: space-between;
            box-sizing: border-box;
            align-items: center;
            padding: 0 1rem;
            &__box{
                width: 100%;
                background-color: #44963B;
                border-radius: 8px;
                text-align: center;
                margin: 0 auto;
                height: auto;
                padding: 1rem;
                h2{
                    color: #fff;
                    margin-top: -1rem;
                    font-size: 1.8rem;
                    letter-spacing: .5px
                };
                p{
                    font-size: 1rem;
                    color: #fff;
                    margin-top: -1rem;
                    padding-bottom: 1rem;
                }

                &__icon{
                    width: 80px;
                    height: 80px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    background-color: #fff;
                    text-align: center;
                    position: relative;
                    bottom: 3rem;
                    box-shadow: 1px 0px 5px 0px rgb(180, 180, 180);
                    img{
                        object-fit: contain;
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }

        &__enterprise{
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &__img{
                width: 100%;

                img{
                    object-fit: contain;
                    height: 300px;
                    width: 100%;
                }
            }

            &__whoiam{
                width: 100%;
                padding: 0;
                label{
                    display: block;
                    font-size: 1rem;
                    font-weight: bold;
                    padding-top: 2rem;
                    color: #125082;
                }
                h2{
                    margin-top: -4px;
                    font-size: 2rem;
                    color: #125082;
                }
                p{
                    margin-top: -2rem;
                    padding: 1rem 0;
                    letter-spacing: 1px;
                    font-weight: 500;
                    font-size: 1rem;
                }
            }
        }

        &__rent{
            width: 100%;
            margin: 3rem auto;
            padding: 2rem 1rem 2rem 1rem;
            display: flex;
            height: auto;
            grid-gap: 4rem;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: center;
            background-color: #44963B;

            &__img{
                width: 100%;
                img{
                    object-fit: contain;
                    height: 300px;
                    width: 100%;
                    position: relative;
                    top: 1rem;
                }
            }

            &__text{
                padding: 0;
                h2{
                    font-size:2rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-top: -1rem;
                    color: #fff;

                }

                p{
                    width: 100%;
                    letter-spacing: 1px;
                    font-size: 1rem;
                    margin-top: -1rem;
                    color: #fff;
                }

                button{
                    width: 193px;
                    color: #fff;
                    height: 52px;
                    border: none;
                    font-size: 1.5rem;
                    font-weight: bold;
                    box-shadow: 1px 0px 5px 0px #0f436d;
                    border-radius: 25px;
                    background-color: #125082;
                }
            }

            
        }


    &__enterprise2{
        width: 100%;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &__img{
            width: 100%;
            img{
                object-fit: contain;
                height: 300px;
                width: 100%;
            }
        }

        &__text{
            width: 100%;
            padding: 0 ;
            h3{
                text-align: center;
                width: 100%;
                font-size: 1.8rem;
                color: #125082;
                line-height: 40px;
            }

            h4{
                text-align: center;
                font-size: 1.2rem;
                color: #125082;
            }
            p{
              text-align: center;
              font-weight: bold;  
              font-size: 1rem;
            }
        }   
    }

    &__plat{
        padding: 0 1rem;
        width: 100%;
        margin: 1rem auto;
        &__icons{
            padding: 0 ;
            width: 100%;
            &__box{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 1rem;
                align-items: center;
                justify-content: space-between;
                div{
                    padding: 1rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: .8rem;
                    width: 100%;
                    border-radius: 27px;
                    height: 70px;
                    box-shadow: 1px 0px 5px 0px #ccc;
                    label{
                        font-weight: 700;
                        width: 100%;
                        text-align: center;
                        color: rgb(114, 114, 114);
                    }
                    img{
                        width: 40px;
                        height: 40px;
                        object-fit: contain;
                    }
                }

            }
        }

        h2{
            text-align: center;
            font-size: 1.8rem;
            line-height: 38px;
            width: 100%;
            color: #125082;
            font-weight: bold;
        }
    }

    &__tec{
        width: 100%;
        margin: 0 auto;
        padding: 2rem 1rem;

        h2{
            text-align: center;
            font-size: 1.8rem;
            line-height: 38px;
            color: #125082;
            width: 100%;
            margin: 0 auto;
            font-weight: bold;
        }
    }
    }

}