.document{
    width: 60%;
    height: 600px;
    margin: 0 auto;
    overflow-y: hidden;
    bottom: 0;
    z-index: 1000;
    border-radius: 8px;
    right: -2rem;
    left: 0;
    top: 15vh;
    box-shadow: 1px 1px 2px 1px #ccc;
    background-color: rgb(255, 255, 255);

}

@media (max-width: 1366px) {
    .document{
        width: 60%;
        height: 570px;
        margin: 0 auto;
        overflow-y: hidden;
        position: fixed;
        bottom: 0;
        z-index: 1000;
        border-radius: 8px;
        left: 0;
        top: 6vh;
        padding-bottom: 2rem;
        box-shadow: 1px 1px 2px 1px #ccc;
        background-color: rgb(255, 255, 255);
    
    }
}