@import '../../../default/variables.scss';

.productDescription{
    width: 100%;
    height: auto;
    padding: 1rem;
    margin-top: 2rem;
    background-color: #f1f1f1;
    border-radius: 9px;
    &__title{
        color: $primary-color;
    }
    &__div{
        padding: 1rem;
        label{
            font-weight: bold;
            display: block;
            padding-top: 1rem;
        }
        ul{
        list-style: none;
            li{
                padding: .5rem;
            }
       }
    }
}

@media (max-width: 760px) {
    .productDescription{
        width: 85%;
        margin: 4rem auto;
    }
}