.subheader{
    background-color: #125082;
    width: 100%;
    padding: 1rem 5.3rem;
    height: 150px;
    margin: 0 auto;
    display: flex;
    align-items:center;
    h1{
        color: #fff;
        letter-spacing: 1px;
        font-size: 1.5rem;
    }

}

@media (max-width: 768px){
    .subheader{
        background-color: #125082;
        width: 100%;
        padding: 1rem 1rem;
        border-top: 1px solid #175f9a;
        height: 100px;
        display: flex;
        align-items:center;
        h1{
            color: #fff;
            letter-spacing: 1px;
            font-size: 1.2rem;
        }
    
    }
}