@import '../../default/variables.scss';

.loginForm{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: grid;
    overflow: none;
    grid-template-columns: 2fr 2fr;
    align-items: center;
    background-color: #fff;
    
    &__background{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
        height: 100%;
    }

    &__divForm{
        padding: 0 7rem;
        width: 100%;
        hr{
            margin-top: 1.5rem;
            box-sizing: border-box;
        }

        &__input, &__buttonSubmitAndForgetPassword{
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__buttonSubmitAndForgetPassword{
            &__recoverPassword{
                width: 100%;
                a{
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        form{
            width: 100%;
        }
    }
}

@media (max-width: 780px) {
    .loginForm{
        grid-template-columns: 1fr;
        &__background{
            display: none;
        }
        &__divForm{
            padding: 2rem;
            &__forgetPassword p a{
                display: block;
            }
        }
        
    }
}