@import '../../default/variables.scss';

.header {
    width: 100%;
    margin: 0 auto;
    z-index: 25;

    &__sub {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: $primary-color;
        padding: .875rem 1rem .875rem 0 !important;
        margin: 0 auto;
        width: 100%;
        height: 60px;
        color: #fff;



        &__infos {
            display: flex;
            list-style: none;
            grid-gap: 0rem;

            @media (max-width: 769px) and (max-width: 1288px) {
                font-size: 12px;
            }

            @media (min-width: 1288px) {
                grid-gap: 2rem;
            }

            & li {
                display: flex;
                align-items: center;
                grid-gap: .5rem;
            }

            a {
                text-decoration: none;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                grid-gap: 4px;
            }
        }

        &__socialMedias {
            display: flex;
            grid-gap: 2rem;
            list-style: none;

            a {
                text-decoration: none;
                color: #fff;
            }
        }
    }

    &__main {
        display: flex;
        height: 100px;
        grid-gap: 1rem;
        align-items: center;
        justify-content: space-between;
        z-index: 25;
        padding: 1rem 5rem;
        box-shadow: 1px 0px 0px 1px rgb(212, 212, 212);

        &__logo {

            img {
                width: 250px;
                object-fit: contain;
                height: 100px;
            }
        }

        ul {
            width: 100%;
            display: block;
            float: left;
            text-align: center;

            a {
                padding: 0 2rem;
                color: #17588e;
            }
        }
    }

    &__links {
        display: flex;
        grid-gap: 2rem;
        align-items: center;
        margin-left: 3rem;
        margin-top: 1rem;
        height: auto;
        justify-content: space-between;

        a {
            display: inline-block;
            margin-left: -1rem;
        }

        &__dropdown {
            width: 200px;
            position: absolute;
            background-color: #fff;
            right: 30.2rem;
            box-shadow: 1px 0px 5px 0px #ccc;
            text-align: left;
            padding: 1rem;

            a {
                display: block;
            }
        }

        @media (min-width: 1400px) {
            &__dropdown {
                width: 200px;
                position: absolute;
                right: 48rem;
                box-shadow: 1px 0px 5px 0px #ccc;
                text-align: left;
                padding: 1rem;

                a {
                    display: block;
                }
            }
        }

        &__dropdownActive {
            display: block;

        }


        &__dropdownDeactive {
            display: none;
        }
    }

    &__links a {
        text-decoration: none;
        color: rgb(63, 63, 63);
        font-size: .9rem;
        font-weight: 600;
    }

    &__links a:hover {
        color: #EB662D;
    }
}



.nav {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem
}

.nav a,
.nav button {
    background: #eee;
    border-radius: .2rem;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    transition: 0.1s;
    cursor: pointer;
}

.nav a:hover,
.nav a:focus,
.nav button:hover,
.nav button:focus {
    background-color: white;
    box-shadow: 0 0 0 3px #eee;
    border-color: #333;
    outline: none;
}

.mobileButton {
    background: transparent;
    color: #f1f1f1;
    border-radius: .2rem;
    height: 40px;
    width: 40px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    cursor: pointer;

}

.mobileButton::after {
    content: '';
    display: block;
    width: 1.2rem;
    height: 2px;
    background-color: currentColor;
    border-radius: 2px;
    box-shadow: 0px 6px currentColor, 0 -6px currentColor;
    transition: .2s;
}



.mobileButton:focus,
.mobileButton:hover,
.mobileButtonActive {
    width: 30px;
    color: #fff;
    outline: none;
    background-color: $primary-color;

}

.mobileButtonActive::after {
    transform: rotate(-90deg);
    width: 4px;
    height: 4px;
    box-shadow: 0px 8px currentColor, 0 -8px currentColor;

}

.headerMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .2rem 1rem;
    background: $primary-color;

    &__nav {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &__ul {
            list-style: none;
            width: 100%;
            text-decoration: none;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0rem;

            li {
                a {
                    text-decoration: none;
                    color: #fff;
                    border: none;
                }
            }
        }
    }
}

.navMobile {
    display: block;
    position: absolute;
    width: 80%;
    height: 100vh;
    top: 55px;
    background-color: $primary-color;
    right: 0px;
    padding: 0 1rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    transform: translateX(-10px);
    opacity: 0;
    pointer-events: none;

    &__menu {
        margin-top: -.3rem;

        a {
            display: block;
            text-decoration: none;
            padding: 1rem 0;
            color: #fff;
            border-bottom: 1px solid #17588e;
            margin-left: -2.3rem;

            :hover {
                background-color: rgb(14, 60, 98);
            }
        }

        &__contact {
            list-style-type: none;
            padding: 1rem 0;

            li {
                padding: .875rem 0;
                color: #fff;
            }
        }
    }


}





.navMobileActive {
    transition: .3s;
    transform: initial;
    opacity: 1;
    z-index: 100;
    pointer-events: initial;
}


.headerMobile a,
.headerMobile button {
    display: flex;
    align-items: center;
    background: none;
    width: 100%;
    border: none;
    border-bottom: 1px solid #eee;
    padding: .5rem 0;
    cursor: pointer;
}


.headerMobile button {
    border-bottom: none;
}

.navMobile svg {
    margin-right: 0.5rem;
}

@media (min-width: 1200px) {
    .header {
        width: 100%;
        margin: 0 auto;

        &__sub {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: $primary-color;
            padding: .875rem 4.6rem .875rem 3rem;
            margin: 0 auto;
            width: 100%;
            color: #fff;

            &__infos {
                display: flex;
                grid-gap: 2rem;
                list-style: none;

                & li {
                    display: flex;
                    align-items: center;
                    grid-gap: .5rem;
                }
            }

            &__socialMedias {
                display: flex;
                grid-gap: 2rem;
                list-style: none;
            }
        }

        &__main {
            display: flex;
            grid-gap: 1rem;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 5rem;

            &__logo {

                img {
                    width: 250px;
                    object-fit: contain;
                    height: 100px;
                }
            }

            ul {
                width: 100%;
                display: block;
                text-align: center;
                margin-left: -2rem;

                a {
                    padding: 0 1.2rem;
                }
            }
        }

        &__links {
            display: flex;
            grid-gap: 4rem;
            margin-left: 4rem;
        }

        &__links a {
            text-decoration: none;
            color: #000;
            font-weight: 500;
        }
    }


}