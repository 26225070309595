@import '../../default/variables.scss';
.footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 5rem 0 0 0;
    padding: 2rem 5rem;
    background-color: $primary-color;

    &__logo{
        margin-top: -4rem;
        img{
            width: 230px;
            height: 200px;
        }
        &__text{
            list-style: none;
            margin-left: -1rem;
            color: #fff;
            margin-top: -5rem;
            
        }
    }
    &__company h4, &__contact h4, &__attendance h4, &__logo h4{
        color: #fff;
    }
    &__company__links{
        list-style: none;
        padding-top: .5rem;
        margin-left: -2rem;
        a{
            display: flex;
            flex-direction: column;
            text-decoration: none;
            color: #fff;
            padding: .3rem 0;
        }
    }

    &__contact{
        &__links{
            padding-top: .5rem;
            margin-left: -2rem;
            list-style: none;
            
            a{
                padding: .3rem 0;
                display: flex;
                text-decoration: none;
                grid-gap: .5rem;
                align-items: center;
                cursor: pointer;
                label{
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }

    &__attendance{ 
        p{
            color: #fff;
            padding-top: .5rem;
        }
        &__links{
            display: flex;
            flex-direction: row;
            list-style: none;
            color: #fff;
            justify-content: space-between;
            width: 70%;
            grid-gap: 1rem;
            margin-left: -2.3rem;
            a{
                text-decoration: none;
                color: #fff;
                display: flex;
                align-items: left;
            }
        
    }
    }
}



.footer_down{
    height: 40px; 
    width: 100%;
    text-align: left;
    padding: 0 5rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    background-color: #0f385a;
    p{
        color: #fff;
    }
}

@media (max-width: 40rem) {
    .footer{
        display: block;
        padding: 2rem 2rem;

        &__logo{
            padding: 0;
            img{
                padding: 0;
                margin-left: -1rem;
            }
            &__text{
                list-style: none;
                margin-left: -1rem;
                color: #fff;
                margin-top: -5rem;
                padding: 0 1rem 0 1rem;
            }
        }
        &__company__links{
            padding-top: 0;
        }

        &__contact{
            &__links{
                padding-top: 0;
            }
        }

        &__attendance{ 
            p{
                padding-top: 0;
            }
          
        }
    }

    .footer_down{
        height: 40px;
        width: 100%;
        text-align: left;
        padding: 0 2rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        background-color: #0f385a;
        p{
            color: #fff;
        }
    }
}