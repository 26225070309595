.warningTraining{

    &__form{
        width: 100%;
        &__aluno1, &__aluno2{
            &__inputs1, &__inputs2{
                display: flex;
                width: 100%;
                flex-direction: row;
                gap: 1rem;
                div{
                    width: 400px;
                    margin-top: 1rem;
                    input{
                        border-radius: 7px;
                        border: 1px solid #ccc;
                        width: 100%;
                        padding: .7rem;
                    }
                }
            }
        }
    }
    &__notTraining{
        margin-top: 2rem;
    }

    &__text{
        width: 55%;
    }
}