.thanks{
    width: 100%;
    margin: 0 auto;
    padding: 1rem 4rem 1rem 4rem;
    text-align: center;
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 4rem;
    &__text{
        font-size: 1.2rem;
        width: 60%;
        font-weight: 700;
        margin: 0 auto;
        padding: 2rem 0 2rem 0;
        color: #125082;
    }

    &__login{
        font-size: 1rem;
    }

}

@media (max-width: 768px){
    .thanks{
        width: 100%;
        padding: 1rem;
        margin-top: -4rem;
        height: auto;
        p{
            font-size: 1rem;
            width: 100%;
            margin-top: -4rem;
        }
    
        svg{
            width: 100px;
        }
    }
}