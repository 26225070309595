.products{
    width: 100%;
    height: 510px;
    margin: 0 auto;
    background-color: #f9f9f9;
    box-shadow: 1px 0px 5px 0px #ccc;
    padding: 1.5rem;
    border-radius: 7px;
    h2{
        text-align: center;
        color:#EA662C ;
    }

    p{

        label{
            color: #000;
            font-weight: 700;
        }
    }

    img{
        margin: 0 auto;
        display: flex;
        width: 329px;
        height: 232px;
        object-fit: contain;
        align-items: center;
        justify-content: center;
    }

        
        a{
            text-decoration: none;
            color: #fff;
            button{
                width: 100%;
                margin: 0 auto;
                color: #fff;
                font-size: 1rem;
                font-weight: bold;
                padding: 1rem;
                border: none;
                border-radius: 12px;
                box-shadow: 1px 0px 2px 0px #125082;
                letter-spacing: 1px;
                background-color: #125082;
                cursor: pointer;
            }
        }
    
}